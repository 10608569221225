<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredLog"
      item-key="name"
      class="elevation-1"
      :search="search"
      :options="options"
      dense
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.check_name="{ item }">
        <v-chip
          :color="getColor(item.check_code)"
          dark
        >
        {{item.check_name}}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      headers: [
        { text: 'Domain', value: 'domain' },
        { text: 'Probe', value: 'probe' },
        { text: 'Latest', value: 'value_latest' },
        { text: 'From', value: 'timestamp_utc' },
        { text: 'Check', value: 'check_name' },
        { text: 'Reason', value: 'check_message'},
        { text: 'Checked at', value: 'check_at' },
      ],
      log: [],
      search: '',
      options: {
        items_per_page: 20
      },
      filter: {
        minCheckCode: 2
      }
    }
  },
  mounted () {
    this.fetchLog()
  },
  computed: {
    filteredLog () {
      return this.log.filter(e => {
        if (this.filter.minCheckCode !== null && e.check_code < this.filter.minCheckCode) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    fetchLog () {
      this.axios.get('/api/state').then(response => {
        console.log(response)
        this.log = response.data
      })
    },
    clearFilter () {
      this.filter.minCheckCode = null
    },
    getColor (code) {
      return {
        0: 'black',
        1: 'green',
        2: 'orange',
        3: 'red'
      }[code]
    }
  }
}


</script>
