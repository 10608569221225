import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'

Vue.use(Vuetify)

const opts = {
    preset,
    rtl: false,
    theme: {
        dark: true
    }
}
export default new Vuetify(opts)
